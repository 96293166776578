const SEATS_PATTERN = /^[\d,]*$/;

export default {
    carrierId: [{ required: true}],
    routeId: [{ required: true}],
    vehicleId: [{ required: true}],
    startTime: [{ required: true}],
    startDate: [{ required: true}],
    openReason: [{ required: true}],
    exceptSeats: [{ required: false }, { pattern: SEATS_PATTERN }],
    seatsOnly: [{ required: false }, { pattern: SEATS_PATTERN }],
}