<template>
  <div>
    <b-col cols="12">
      <h2>График работы транспортных средств</h2>
      <div class="d-flex">
        <b-card class="mb-4 mt-4">
          <b-form-group label="Выберите перевозчика" class="mb-0">
            <b-form-select :value="carrierId" @change="load">
              <b-form-select-option :value="null">Выберите перевозчика</b-form-select-option>
              <b-form-select-option v-for="carrier in carriers" :value="carrier.id" :key="carrier.id">
                {{ carrier.carrierName }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-card>
      </div>
    </b-col>
    <b-overlay :show="loading">
      <b-col cols="12" class="d-flex">
        <b-card class="w-100 mb-4" v-if="carrierId">
          <b-table :items="vehiclesSchedule" :small="true" responsive bordered striped>
            <template #head(bus)="">
              <div class="text-nowrap">
                Г/н ТС
              </div>
            </template>
            <template #head()="scope">
              <div class="text-nowrap">
                {{ labelFormat(scope.label)}}
              </div>
            </template>
            <template #cell(bus)="data">
              <b>{{data.value.gosNumber}}</b>
              <div style="font-size: 12px" class="text-nowrap">(гар. № {{data.value.garageNumber}})</div>
              <div style="font-size: 10px" class="text-nowrap">Схема ({{data.value.busSchema.seatsCount}} мест)</div>
            </template>
            <template #cell()="data">
              <div class="text-center">
                <div v-for="(trip, index) in data.value" :key="index">
                  <div v-b-tooltip :title="trip.route.name">{{trip.route.number}}</div>
                  <i class="trip-time">(прямой {{getTime(trip.startTime)}})</i>
                </div>
                <a href="#" @click.prevent="showModalNewTrip(data.index, data.field)" v-b-tooltip title="Установить рейс">+</a>
              </div>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-overlay>
    <b-modal
        id="modal-prevent-closing"
        ref="modal"
        title="Создание рейса"
        hide-footer
        size="lg"
    >
      <Form ref="form" btn-title="Создать" btn-variant="primary" @onSave="createTrip"/>
    </b-modal>
  </div>
</template>

<script>
import Form from './VehicleSchedule/Form'
import {mapState} from "vuex";
import moment from "moment";
moment.locale('ru')

export default {
  name: "VehicleSchedule",
  components: {
    Form
  },
  data() {
    return {

    }
  },
  methods: {
    createTrip(form) {
      this.$store.dispatch('App/startLoad')
      this.$store.dispatch('Trips/create', form)
          .then(() => {
            this.$refs.modal.hide()
            this.$refs.form.resetForm()
            this.$bvToast.toast('Рейс был успешно создан.', {
              title: 'Успех',
              autoHideDelay: 5000,
              variant: 'success',
              appendToast: true
            })
          })
          .finally(() => {
            this.$store.dispatch('App/stopLoad')
            this.$store.dispatch('VehiclesSchedule/load', { carrierId: this.carrierId })
          })
    },
    showModalNewTrip(index, field) {
      const day = field.key
      const busId = this.vehiclesSchedule[index].bus.id

      this.$refs.modal.show()
      setTimeout(() => {
        this.$refs.form.fillForm({
          vehicleId: busId,
          startDate: day,
          carrierId: this.carrierId
        })
      }, 0);
    },
    getTime(time) {
      return moment(time).format('HH:mm')
    },
    labelFormat(date) {
      return moment(date).format('DD.MM dd')
    },
    fields() {
      return [
        {
          key: 'bus',
          label: 'г/н ТС',
          stickyColumn: true,
          formatter: (value) => value.gosNumber
        }
      ]
    },
    linkGen(pageNum) {
      return pageNum === 1 ? '?' : `?page=${pageNum}`
    },
    load(carrierId) {
      this.$store.dispatch('VehiclesSchedule/load', { carrierId })
    }
  },
  computed: {
    ...mapState({
      carriers: state => state.Carriers.items,
      loading: state => state.VehiclesSchedule.loading,
      vehiclesSchedule: state => state.VehiclesSchedule.items,
      carrierId: state => state.VehiclesSchedule.carrierId
    })
  }
}
</script>

<style scoped>
  .trip-time {
    font-size: 10px;
    white-space: nowrap;
  }
</style>