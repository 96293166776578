<template>
  <b-form>
    <b-row>
      <b-col md="6">
        <b-form-group label="Выберите перевозчика">
          <b-form-select id="carrier" v-model="form.carrierId" :state="formErrors.carrierId">
            <b-form-select-option :value="null" :key="null">Выберите перевозчика</b-form-select-option>
            <b-form-select-option v-for="carrier in carriers" :value="carrier.id" :key="carrier.id">
              {{ carrier.carrierName }}
            </b-form-select-option>
          </b-form-select>
        </b-form-group>
        <b-form-group label="Наименование и номер маршрута">
          <b-form-select id="route" v-model="form.routeId" :state="formErrors.routeId">
            <b-form-select-option :value="null" :key="null">Выберите маршрут</b-form-select-option>
            <b-form-select-option v-for="route in routes" :value="route.id" :key="route.id">
              {{ route.number }} - {{ route.name }}
            </b-form-select-option>
          </b-form-select>
        </b-form-group>
        <b-form-group label="Транспортное средство">
          <b-form-select id="bus" v-model="form.vehicleId" :state="formErrors.vehicleId">
            <b-form-select-option :value="null" :key="null">Выберите ТС</b-form-select-option>
            <b-form-select-option v-for="vehicle in vehicles" :value="vehicle.id" :key="vehicle.id">
              {{ vehicle.gosNumber }} - {{ vehicle.mark }} {{ vehicle.model }}
            </b-form-select-option>
          </b-form-select>
        </b-form-group>
        <b-form-group label="Дата выезда">
          <b-form-datepicker id="datepicker" v-model="form.startDate" :start-weekday="1"  class="mb-2" :state="formErrors.startDate" label-no-date-selected=""></b-form-datepicker>
        </b-form-group>
        <b-form-group label="Время выезда">
          <b-form-timepicker id="timepicker"  v-model="form.startTime"  class="mb-2" :state="formErrors.startTime" label-no-time-selected=""></b-form-timepicker>
        </b-form-group>
      </b-col>
      <b-col md="6">

        <b-form-radio-group
            v-model="selectedRestrict"
            aria-controls="ex-disabled-readonly"
        >
          <b-form-group>
            <b-form-radio value="exceptSeats" class="mb-2">Продавать все, кроме</b-form-radio>
            <b-form-input v-model="form.exceptSeats" :state="formErrors.exceptSeats" :disabled="selectedRestrict !== 'exceptSeats'"/>
            <b-form-text>Укажите места недоступные для продажи. Номера мест через запятую</b-form-text>
          </b-form-group>
          <b-form-group>
            <b-form-radio value="seatsOnly" class="mb-2">Продавать только</b-form-radio>
            <b-form-input v-model="form.seatsOnly" :state="formErrors.seatsOnly" :disabled="selectedRestrict !== 'seatsOnly'"/>
            <b-form-text>Укажите места доступные для продажи. Номера мест через запятую</b-form-text>
          </b-form-group>
        </b-form-radio-group>
        <b-form-group label="Причина открытия (комментарий)">
          <b-form-input v-model="form.openReason" :state="formErrors.openReason"/>
          <b-form-text>Укажите причину, по которой открыт дополнительный рейс. Обязательно к заполнению.</b-form-text>
        </b-form-group>
      </b-col>
      <b-col md="12">
        <slot></slot>
      </b-col>
    </b-row>
    <div class="text-right mt-4">
      <b-button :variant="btnVariant" @click="onSave">{{ btnTitle }}</b-button>
    </div>
  </b-form>
</template>

<script>
import validate from "@/utils/formValidator";
import TRIP from "@/forms/TRIP";
import {mapState} from "vuex";
import {phoneFormat} from "@/utils/phone";

const TRIP_INIT_FORM = () => ({
  carrierId: null,
  routeId: null,
  vehicleId: null,
  startTime: null,
  startDate: null,
  exceptSeats: '',
  seatsOnly: '',
  openReason: ''
})

export default {
  name: "Form",
  props: {
    init: { required: false, default: () => TRIP_INIT_FORM() },
    btnVariant: { required: true },
    btnTitle: { required: true }
  },
  data() {
    return {
      selectedRestrict: 'exceptSeats',
      form: this.init,
      errors: {}
    }
  },
  watch: {
    form: {
      handler: function () {
        this.validateForm()
      },
      deep: true
    }
  },
  computed: {
    ...mapState({
      carriers: state => state.Carriers.items,
      routes: state => state.Routes.items,
      vehicles: state => state.Vehicles.items
    }),
    formErrors: function () {
      return Object.fromEntries(Object.entries(this.errors).map((item) => [item[0], !item[1]]))
    }
  },
  methods: {
    fillForm(form) {
      console.log('1231233223454356456')
      this.form = {
        ...this.form,
        ...form
      }

      if (this.form.seatsOnly) {
        this.selectedRestrict = 'seatsOnly'
      } else if(this.form.exceptSeats) {
        this.selectedRestrict = 'exceptSeats'
      }
    },
    phoneFormat: (value) => phoneFormat(value),
    resetForm() {
      Object.assign(this.form, TRIP_INIT_FORM())
      this.errors = {}
    },
    validateForm() {
      this.errors = validate(this.form, TRIP)
      return Object.keys(this.errors).length === 0
    },
    onSave() {
      if (!this.validateForm())
        return

      let form = { ...this.form }


      if (this.selectedRestrict === 'exceptSeats') {
        form.seatsOnly = ''
      } else if(this.selectedRestrict === 'seatsOnly') {
        form.exceptSeats = ''
      }

      form.startTime = form.startDate + ' ' + form.startTime
      delete form.startDate

      this.$emit('onSave', form)
    }
  }
}
</script>

<style scoped>

</style>